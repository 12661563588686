import React, {useEffect, useState} from "react";
import {Anchor, Box, Heading, Image, Markdown, Text} from "grommet";
import {useParams} from "react-router";
import unescapeJS from "unescape-js";
import {Cycle} from "grommet-icons";

import Section from "../components/Section";
import Emoji from "../components/Emoji";

const BlogPost = () => {
	const params = useParams();
	const [postData, setPostData] = useState();
	const [isReady, setIsReady] = useState(false);
	const [isLiked, setIsLiked] = useState(0);
	// const [likeAction, setLikeAction] = useState(0);
	
	// useEffect(() => {
	// 	console.log(isLiked);
	// 	console.log("----");
	// 	if (isLiked !== 1 || isLiked !== -1) return;
	// 	// let action = likeAction === 1 ? "like" : "unlike";
	// 	// fetch(`https://nourman.id/api/post/${action}/${postData.id}`, {
	// 	// 	method: "GET"
	// 	// });
	// 	setIsLiked(prevState => prevState + likeAction);
	// 	setLikeAction(0);
	// }, [likeAction, isLiked]);
	
	useEffect(() => {
		fetch(`/api/get-post/${params.slug}`, {
			method: "GET"
		}).then(response => {
			return response.json();
		}).then(parsedResponse => {
			let post = parsedResponse[0];
			post.body = unescapeJS(post.body);
			setPostData(post);
			setIsReady(true);
		});
	}, []);
	
	return (
		<>
			<Section
				background="accent-5"
				border="black"
				blogPost
				title={<>
					{/*Go Back to BlogHome Button*/}
					<Box
						background="accent-4"
						pad={{
							vertical: "small",
							horizontal: "medium"
						}}
						round="small"
						margin={{vertical: "medium"}}
					>
						<Anchor alignSelf="center" href="/blog">
							&larr; I want to read more post <Emoji symbol="📝" label="blog"/>
						</Anchor>
					</Box>
					
					{!isReady ? (
						<>
							<Box margin={{top: "large"}} alignSelf="center" animation="jiggle">
								<Cycle size="large"/>
							</Box><br/>
							<Text size="large" textAlign="center">
								Fetching post...<br/>
								Please wait for a few seconds :)
							</Text>
						</>
					) : (
						<>
							{/*Post Title*/}
							<Heading
								textAlign="center" style={{maxWidth: "850px", overflowWrap: "break-word"}}
								alignSelf="center" level="1" margin={{top: "medium"}}
							>
								<Anchor href="post">
									{postData.title}
								</Anchor>
							</Heading>
							<Text size="large" textAlign="center" margin={{top: "none", bottom: "large"}}>
								Published on <b>{postData.publishedOn}</b> in <b>{postData.category}</b>
							</Text>
							
							{/*Display Image*/}
							<Image
								alignSelf="center"
								width="100%"
								fit="contain"
								src={postData.image}
								className="imageDisplay"
								margin={{bottom: "large"}}
							/>
							
							{/*Post Content*/}
							<Markdown className="markdown">
								{postData.body}
							</Markdown>
							
							{/*Like Button*/}
							<Box
								background="accent-3"
								alignSelf="center"
								pad={{
									vertical: "small",
									horizontal: "medium"
								}}
								round="small"
								border={{
									side: "all",
									size: "medium",
									color: "accent-2"
								}}
								margin={{top: "large"}}
							>
								<Anchor
									alignSelf="center"
									onClick={() => {
										if (isLiked === 1) setIsLiked(-1);
										else setIsLiked(1);
									}}
								>
									I LIKE THIS! <Emoji symbol="👍" label="like"/>
								</Anchor>
							</Box>
						</>
					)}
				</>}
			/>
		</>
	);
};

export default BlogPost;