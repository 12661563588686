import React from "react";
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import {Grommet} from "grommet";
import {deepMerge} from "grommet/utils";
import WebFont from "webfontloader";

import {grommet} from "grommet/themes";
import {siteTheme} from "./siteTheme";

import "normalize.css";
import "./index.css";

import Home from "./screens/Home";
import BlogHome from "./screens/BlogHome";
import NotFound404 from "./screens/NotFound404";
import BlogPost from "./screens/BlogPost";
import Footer from "./components/Footer";
import Header from "./components/Header";

const myTheme = deepMerge(grommet, siteTheme);

WebFont.load({
	google: {
		families: [
			"Fira Sans Condensed:300",
			"DM Serif Display",
			"Nanum Gothic Coding&display=swap",
		]
	},
	custom: {
		families: ["Butler ExtraBold"],
		urls: ["/assets/fonts.css"]
	}
});

const App = () => {
	return (
		<Router>
			<Grommet theme={myTheme} id="grommetParent" full>
				<Header/>
				<Switch>
					<Route exact path="/" component={Home}/>
					<Route exact path="/blog" component={BlogHome}/>
					<Route path="/blog/:slug" component={BlogPost}/>
					<Route path="/404" component={NotFound404}/>
					<Route component={NotFound404}/>
				</Switch>
				<Footer/>
			</Grommet>
		</Router>
	);
};

export default App;
