/* eslint-disable no-irregular-whitespace */
import React from "react";
import {Redirect, useLocation} from "react-router";
import {Anchor, Box, Heading, Image, Text} from "grommet";

import Section from "../components/Section";
import Emoji from "../components/Emoji";

const NotFound404 = () => {
	//redirect to /404
	let location = useLocation();
	if (location.pathname !== "/404") {
		return (<Redirect to="/404"/>);
	}
	
	return (
		<>
			<Section
				background="accent-4"
				fill fullPage
				border="black"
				left={<>
					<Box
						width="350px"
						height="350px"
						background="accent-3"
						round="full"
						id="siteAvatarShadow"
					/>
					<Image
						alignSelf="center"
						width="350px"
						fit="contain"
						src="/assets/avabackground.gif"
						id="siteAvatarBackground"
						draggable="false"
					/>
					<Text textAlign="center" color="accent-3" size="350%" id="siteAvatarText" className="serif">
						NOT<br/>FOUND
					</Text>
				</>}
				right={<>
					<Heading
						alignSelf="center" color="accent-1" level="1" size="xlarge"
						margin={{top: "none", bottom: "medium"}}
					>
						404 :(
					</Heading>
					<Anchor alignSelf="center" href="/">
						&larr; Back to the homepage <Emoji symbol="🏠" label="home"/>
					</Anchor>
				</>}
			/>
		</>
	);
};

export default NotFound404;